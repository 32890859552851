import React from 'react'

export default function ArrowLinkOutSideBox (props) {
    return (
      <svg {...props} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 1.334v2H3.593a.26.26 0 0 0-.26.258v8.816c0 .142.117.259.26.259h8.814a.26.26 0 0 0 .26-.26V10h2v2.408a2.262 2.262 0 0 1-2.26 2.259H3.593a2.262 2.262 0 0 1-2.26-2.26V3.593a2.262 2.262 0 0 1 2.26-2.258H6zm8.333 0c.184 0 .334.149.334.333v6.29c0 .297-.36.446-.57.236L12.045 6.14a.334.334 0 0 0-.471 0L8.617 9.097a.332.332 0 0 1-.472 0L6.903 7.855a.332.332 0 0 1 0-.472l2.956-2.956a.334.334 0 0 0 0-.472L7.807 1.903a.333.333 0 0 1 .236-.57z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    )
  }
import React, { useState } from 'react'
import { ClickOutside } from '@truphone/utils'
import { SecondaryButton } from '@truphone/buttons'
import { Close } from '@truphone/icons'

export default function ModalComponent(props) {
  const [open, setOpen] = useState(false)

  return (
    <ClickOutside onClickOutside={() => setOpen(false)}>
      <div className={`${props.mobile ? 'flex' : 'hidden xl:flex'} text-black relative`}>
        {props.secondary ? (
          <SecondaryButton className="flex-grow cursor-pointer m-2" onClick={() => setOpen(true)}>
            {props.text}
          </SecondaryButton>
        ) : (
          <a
            className={`flex-grow bg-blue-100 text-white font-bold rounded-sm px-6 py-2 uppercase tracking-xwidest cursor-pointer whitespace-nowrap`}
            onClick={() => setOpen(true)}
          >
            {props.text}
          </a>
        )}
        {open && (
          <div
            className={`min-w--340 min-w--378 absolute ${
              props.mobile ? 'bottom-32' : 'top-10'
            } right-0 bg-white z-10 rounded-sm shadow-md`}
          >
            <div className="border-b border-grey-200 py-2 px-4 flex justify-between">
              <div>
                <h5 className="text-sm font-bold tracking-wide">{props.modal.title}</h5>
                <p className="text-sm opacity-60 tracking-wide">{props.modal.text}</p>
              </div>
              <Close onClick={() => setOpen(false)} className="cursor-pointer ml-auto" />
            </div>
            <div className="flex flex-wrap justify-center items-center py-4 px-4">
              {props.modal.appStore && (
                <a
                  data-app-store="Apple"
                  href={props.modal.appStore.href}
                  className="block flex-shrink-0 m-2"
                >
                  <img src={props.modal.appStore.image} alt="appstore" />
                </a>
              )}
              {props.modal.playStore && (
                <a
                  data-app-store="Google"
                  href={props.modal.playStore.href}
                  className="block flex-shrink-0 m-2"
                >
                  <img src={props.modal.playStore.image} alt="playstore" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </ClickOutside>
  )
}

import React from 'react'
export default function Arrow (props) {
    return (
      <svg {...props} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m14 8-6 6-6-6M8 14V2" />
        </g>
      </svg>
    )
  }
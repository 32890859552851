export default function uppercaseString(string) {
  return string
    ? string
        .toUpperCase()
        .replace('ESIM', 'eSIM')
        .replace('ISIM', 'iSIM')
        .replace('IOT', 'IoT')
        .replace('IPAD', 'iPAD')
        .replace('IPHONE', 'iPHONE')
        .replace('MIFID', 'MiFID')
        .replace('WIFI', 'WiFi')
        .replace('IBAHN', 'iBAHN')
    : null
}

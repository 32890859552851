import { LinkWrap } from '@truphone/gatsby-wrappers'
import React from 'react'

export default function MenuDropdown(props) {
  const handleClick = (item) => {
    props.handleClick && props.handleClick(item.text)
  }
  return (
    <div className="w-full shadow-md bg-white text-black whitespace-normal rounded min-w-menu">
      {props.list.map((item, i) => {
        return (
          <LinkWrap
            className="text-sm block p-3 text-current hover:text-current hover:bg-grey-100"
            href={item.href}
            key={`item-${i}`}
            onClick={() => handleClick(item)}
          >
            {item.text}
          </LinkWrap>
        )
      })}
    </div>
  )
}

import React from 'react'
import './hamburger.css'

export default function Hamburger(props) {
  return (
    <div
      className={`${props.active ? 'active text-white' : ''} ${
        props.dark || props.isTriggered ? 'text-black' : 'text-white'
      } hamburger inline-block`}
    >
      <div className="relative inline-block w-6 h-6">
        <div className="hamburger-inner absolute top-1/2 block w-6 h-1 tranform bg-current rounded-sm"></div>
      </div>
    </div>
  )
}

import React, { useContext, useEffect } from 'react'
import { PreferencesContext } from '../contexts/PreferencesProvider'

const bodyScript = (gtmId) => `
(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
      'gtm.start':
          new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          '//www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${gtmId}');`

const noScript = (gtmId) =>
  `<iframe src="//www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`

const TagManager = (props) => {
  const context = useContext(PreferencesContext)

  useEffect(() => {
    if (props.enabled) {
      if (context.analytical === true || context.marketing === true) {
        const noScriptElement = document.createElement('noscript')
        noScriptElement.innerHTML = noScript(props.gtmId)
        document.body.append(noScriptElement)

        const jQuery = document.createElement('script')
        jQuery.src = 'https://code.jquery.com/jquery-3.3.1.min.js'
        document.body.append(jQuery)

        const script = document.createElement('script')
        script.innerHTML = bodyScript(props.gtmId)
        document.body.append(script)

        const optimizeScript = document.createElement('script')
        optimizeScript.src = `https://www.googleoptimize.com/optimize.js?id=OPT-W346ZWB`
        document.body.append(optimizeScript)
      }
    }
  }, [props.enabled, context.analytical, context.marketing])

  return <></>
}

export default TagManager

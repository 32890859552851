import React from 'react'

export default function Testimonial(props) {
  return (
    <a
      className="block p-2 hover:text-blue-100"
      target={props.target}
      title={props.title}
      rel={props.rel}
      href={props.href}
    >
      <div className="w-44">
        <div className="relative">
          <figure className="h-15 overflow-hidden relative">
            <img className="max-w-none relative block" src={props.image} alt="" />
          </figure>
          <figure className="p-1 absolute top-1/2 transform -translate-y-1/2 left-3 h-10 w-15 bg-white flex rounded-sm">
            <img className="m-auto max-w-full max-h-full relative block" src={props.logo} alt="" />
          </figure>
        </div>
        <div className="py-2">
          <h5 className="mb-1">{props.text}</h5>
        </div>
      </div>
    </a>
  )
}

import React from 'react'

export default function NewsAndPress(props) {
  return (
    <a
      className={`block ${
        props.events ? 'w-1/3' : 'w-1/4'
      } min-w--150 hover:text-blue-100 hover-black`}
      target={props.target}
      title={props.title}
      rel={props.rel}
      href={props.href}
    >
      <div className="pr-6 pb-6">
        <p className="mb-1 opacity-60">
          {props.date &&
            new Date(props.date).toLocaleDateString(props.locale, {
              month: 'short',
              day: 'numeric'
            })}

          {props.readingTime && <> • {props.readingTime}</>}
        </p>
        <p className="mb-1">{props.content}</p>
      </div>
    </a>
  )
}

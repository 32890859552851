import React, { useState } from 'react'
import { uppercaseString } from '@truphone/utils'
import { Accordion } from '@truphone/accordion'
import { ArrowButton } from '@truphone/buttons'
import { ArrowSimple } from '@truphone/icons'
import '../style.css'
import Testimonial from './Testimonial'
import ContentHubItem from './ContentHubItem'
import NewsAndPress from './NewsAndPress'
import {LinkWrap} from '@truphone/gatsby-wrappers'

const ScrolledList = (props) => {
  return (
    <div className="flex flex-nowrap w-full py-4 overflow-x-auto text-xs">
      {props.items &&
        props.items.map((menuItem, i) => {
          return (
            <div
              className={`p-2 ${props.testimonials || props.newsAndPress ? '' : 'text-black'}`}
              key={`scrolled-list-${i}`}
            >
              {props.testimonials ? (
                <Testimonial {...menuItem} />
              ) : props.newsAndPress ? (
                <NewsAndPress {...menuItem} />
              ) : (
                <ContentHubItem {...menuItem} />
              )}
            </div>
          )
        })}
    </div>
  )
}

export default function MobileList(props) {
  const [expanded, setExpanded] = useState(false)
  const [expandedSub, setExpandedSub] = useState(false)

  const handleChange = (panel) => {
    setExpanded(panel !== expanded ? panel : false)
    setExpandedSub(false)
  }

  const handleChangeSub = (panel) => {
    setExpandedSub(expandedSub !== panel ? panel : false)
  }

  const handleOpen = () => {
    if (props.setOpen) {
      props.setOpen()
    }
  }
  return (
    <div
      className={`${
        props.active ? 'block' : 'hidden'
      } absolute left-1/2 -translate-x-1/2 transform max-w-sm top-0 pb-32 px-4 w-full`}
    >
      <button onClick={props.goBack} className="flex items-center py-2">
        <ArrowSimple className={`w-5 mr-4 transform rotate-90`} />
        <span className="text-2xl">{props.backButton}</span>
      </button>
      {props.items && (
        <ul className="pl-4">
          {props.items.map((item, i) => {
            return item.items && item.items.length > 0 ? (
              <Accordion
                key={`list-item-${i}`}
                {...item}
                expanded={expanded === `list-item${i}`}
                onChange={() => handleChange(`list-item${i}`)}
                mainItem
              >
                {item.href !== '' && props.overview && (
                  <li
                    className={`${
                      item.items[0].items && item.items[0].items.length
                        ? 'border-b border-white border-opacity-30'
                        : ''
                    }`}
                  >
                    <LinkWrap
                      onClick={() => handleOpen()}
                      key={`list-subitem`}
                      href={item.href}
                      className="block text-sm py-3"
                    >
                      {props.overview}
                    </LinkWrap>
                  </li>
                )}

                {item.links && (
                  <div>
                    {item.links.map((link) => {
                      return (
                        <li key={`list-link-${i}`}>
                          <LinkWrap
                            href={link.href}
                            className="block text-sm py-3"
                            onClick={() => handleOpen()}
                          >
                            {link.text}
                          </LinkWrap>
                        </li>
                      )
                    })}
                  </div>
                )}

                {item.items && item.scrolledList ? (
                  <div key={`list-subitem-blog-${i}`}>
                    <ScrolledList
                      testimonials={item.testimonials}
                      newsAndPress={item.newsAndPress}
                      items={item.items}
                    />
                    {item.seeAll && (
                      <div className="py-3">
                        <ArrowButton href={item.seeAll.href} className="text-sm block text-white">
                          {item.seeAll.text}
                        </ArrowButton>
                      </div>
                    )}
                  </div>
                ) : (
                  item.items.map((subitem, i) => {
                    return subitem.items && subitem.items.length > 0 ? (
                      <Accordion
                        key={`list-subitem-${i}`}
                        expanded={expandedSub === `list-subitem${i}`}
                        onChange={() => handleChangeSub(`list-subitem${i}`)}
                        title={subitem.text}
                        {...subitem}
                      >
                        {subitem.items &&
                          subitem.items.map((childitem, i) => {
                            return (
                              <LinkWrap
                                key={`mobile-childitem-${i}`}
                                href={childitem.href}
                                className="block text-sm py-3 pr-2 pl-4"
                                onClick={() => handleOpen()}
                              >
                                {childitem.text}
                              </LinkWrap>
                            )
                          })}
                      </Accordion>
                    ) : subitem.imgWhiteUrl || subitem.imgUrl ? (
                      <li
                        key={`list-subitem-img-${i}`}
                        className="w-full flex items-center justify-between"
                      >
                        <div className="mt-4 pr-4">
                          <div className="flex">
                            <img
                              className="mr-2"
                              src={subitem.imgWhiteUrl || subitem.imgUrl}
                              alt=""
                            />
                            {subitem.title && (
                              <p className="overline-text">{uppercaseString(subitem.title)}</p>
                            )}
                          </div>
                          <p className="text-xs mt-2 mb-4">{subitem.subtitle}</p>
                        </div>
                        <ArrowSimple
                          className={`w-3 transform ${subitem.expanded ? 'rotate-180' : ''}`}
                        />
                      </li>
                    ) : (
                      <li key={`list-subitem-${i}`}>
                        <LinkWrap
                          href={subitem.href}
                          className="block text-sm py-3"
                          onClick={() => handleOpen()}
                        >
                          {subitem.text}
                        </LinkWrap>
                      </li>
                    )
                  })
                )}
              </Accordion>
            ) : item.imgWhiteUrl || item.imgUrl ? (
              <li
                key={`list-item-img-${i}`}
                className="w-full flex items-center justify-between border-b border-white border-opacity-30"
              >
                <LinkWrap href={item.href} className="mt-4 pr-4" onClick={() => handleOpen()}>
                  <div className="flex">
                    <img className="mr-2" src={item.imgWhiteUrl || item.imgUrl} alt="" />
                    {item.title && <p className="overline-text">{uppercaseString(item.title)}</p>}
                  </div>
                  <p className="text-xs mt-2 mb-4">{item.subtitle}</p>
                </LinkWrap>
              </li>
            ) : (
              <li
                key={`list-subitem-img-${i}`}
                className="w-full flex items-center justify-between border-b border-white border-opacity-30"
              >
                <LinkWrap href={item.href} className="mt-4 py-2 pr-4" onClick={() => handleOpen()}>
                  <p className="overline-text">
                    {uppercaseString(item.text ? item.text : item.title)}
                  </p>
                </LinkWrap>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function OutlinedButtonBlack({ className, children, ...props }) {
  className = cx(
    'text-base md:text-xl font-medium no-underline py-3 md:py-2 px-5 md:px-10  flex sm:inline-flex items-center justify-center text-black border-black border-2 leading-tight rounded-lg hover:bg-gray-300',
    className
  )
  return (
    <LinkWrap className={className} {...props}>
      {children}
    </LinkWrap>
  )
}

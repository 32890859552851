import React from 'react'

export default function ContentHubItem(props) {
  return (
    <a
      className="block p-2 h-full"
      target={props.target}
      title={props.title}
      rel={props.rel}
      href={props.href}
    >
      <div className="w-44 h-full shadow-sm bg-white rounded-sm overflow-hidden hover:shadow-lg">
        <figure className="h-24 overflow-hidden relative">
          <img className="max-w-none relative block" src={props.image} alt="" />
          {props.type && (
            <h6 className="absolute bottom-2 left-3 uppercase font-bold bg-white rounded-sm px-3 py-1">
              {props.type}
            </h6>
          )}
        </figure>
        <div className="px-2 py-4">
          <p className="mb-1">{props.title}</p>
          <p>{props.content}</p>
          {props.footer && <p className="mb-1 opacity-60">{props.footer}</p>}
        </div>
      </div>
    </a>
  )
}

const geoLookup = async () => {
  let geo = sessionStorage.getItem('geo') && JSON.parse(sessionStorage.getItem('geo'))
  if (geo) {
    return geo
  }

  const response = await fetch('https://web.truphone.com/api/geo/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })

  if (response.ok) {
    const json = await response.json()
    sessionStorage.setItem('geo', JSON.stringify(json))
    return json
  }
}

export default geoLookup

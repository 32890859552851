import React, { useContext, useState, useEffect } from 'react'
import { Menu, Footer, CookiePrompt } from '@truphone/navigation-components'
import PreferencesProvider, { PreferencesContext } from '../../contexts/PreferencesProvider'
import LocaleProvider, { LocaleContext } from '../../contexts/LocaleProvider'
import { Helmet } from 'react-helmet'
import TagManager from '../TagManager'
import { detectIEEdge } from '../../utils'
import './ie-styles.css'
import clsx from 'clsx'
import LogoImage from '../../images/logos/truphone-logo.svg'
import LogoWhiteImage from '../../images/logos/truphone-logo-white.svg'
import LogoConsumerImage from '../../images/logos/truphone-logo-consumer.svg'
import LogoConsumerWhiteImage from '../../images/logos/truphone-logo-consumer-white.svg'
import LogoFooterImage from '../../images/logos/truphone-footer.svg'

const Logo = () => <img src={LogoImage} alt="" placeholder="none" />
const LogoWhite = () => <img src={LogoWhiteImage} alt="" placeholder="none" />
const ConsumerLogo = () => <img src={LogoConsumerImage} alt="" placeholder="none" />
const ConsumerLogoWhite = () => <img src={LogoConsumerWhiteImage} alt="" placeholder="none" />
const FooterLogo = () => <img src={LogoFooterImage} alt="" placeholder="none" />

function CookiePromptWrap(props) {
  const preferencesContext = useContext(PreferencesContext)
  const localeContext = useContext(LocaleContext)

  return (
    <CookiePrompt
      locale={localeContext.iso}
      onCookiePreferencesConfirmed={(preferences) => preferencesContext.setPreferences(preferences)}
      {...props}
    />
  )
}

function TailwindLayout(props) {
  const [isIE, setIsIE] = useState(false)
  useEffect(() => setIsIE(detectIEEdge()), [])

  const [prevScroll, setPrevScroll] = useState(0)
  const [isTriggered, setTriggered] = useState(false)

  const [menuStyle, setMenuStyle] = useState(
    clsx({
      ['relative']: isTriggered
    })
  )
  useEffect(() => {
    const onScroll = (e) => {
      setPrevScroll(e.target.documentElement.scrollTop)
      const triggered = e.target.documentElement.scrollTop > 100
      const isGoingDown = e.target.documentElement.scrollTop > prevScroll
      const newMenuStyle = clsx({
        ['relative']: !triggered,
        ['fixed top-0 w-full z-20']: props.secondaryMenu ? !isGoingDown : triggered,
        ['fixed top-0 w-full transform -translate-y-full']: props.secondaryMenu
          ? triggered && isGoingDown
          : false
      })
      if (newMenuStyle.toString() === menuStyle.toString()) return

      setTimeout(() => {
        setMenuStyle(newMenuStyle)
        setTriggered(triggered)
      }, 500)
    }

    //Disable menu scroll on gated content
    if (props.menu && !props.menu.gated) {
      window.addEventListener('scroll', onScroll)
    }
    return () => window.removeEventListener('scroll', onScroll)
  }, [menuStyle, prevScroll])

    const darkText = props.menu.dark || props.consumer
    return (
      <div>
        {isIE ? (
          <div className="bg--gray w-full h-screen flex items-center">
            <div className="bg--white m-auto max-w-2xl flex p-12 rounded shadow--lg">
              <div className="w-1/2 pr-12 ">
                <img src="/images/404.svg" alt="" />
              </div>
              <div className="w-1/2 flex flex-col">
                <h1 className="text-2xl mb-4">Internet Explorer is not supported</h1>
                <p className="text-sm mb-4">
                  You&apos;ll need to use a supported browser e.g. Chrome, Edge, Firefox or Safari.
                </p>
                <a
                  href="mailto:help@truphone.com"
                  className="mt-auto inline-block tracking-wider text-gray-500 uppercase font-bold"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        ) : (
          <PreferencesProvider>
            <Helmet>
              <link rel="preconnect" href="https://www.datocms-assets.com" crossOrigin></link>
            </Helmet>
            {props.cookiePrompt && (
              <LocaleProvider>
                <CookiePromptWrap {...props.cookiePrompt} />
              </LocaleProvider>
            )}
            <TagManager enabled={process.env.GTM_ENABLED === 'true'} gtmId={process.env.GTM_ID} />
            <div id="page">
              <div className={menuStyle}>
                <Menu
                  {...props.menu}
                  dark={darkText}
                  isTriggered={isTriggered}
                  logo={
                    <>
                      {!props.overrideLogo && <>{props.consumer ? <ConsumerLogo /> : <Logo />}</>}
                    </>
                  }
                  logoUrl={props.overrideLogo}
                  logoWhite={
                    <>
                      {!props.overrideLogo && (
                        <>{props.consumer ? <ConsumerLogoWhite /> : <LogoWhite />}</>
                      )}
                    </>
                  }
                  logoWhiteUrl={props.overrideLogo}
                  primaryButton={props.primaryButton || props.menu.primaryButton}
                  hiddenSub={props.menu.hiddenSub}
                  page={props.page}
                />
              </div>

              <div className="page-main relative">{props.children}</div>
              {props.footer && (
                <Footer
                  {...props.footer}
                  logo={<FooterLogo />}
                  business={!props.consumer}
                  consumer={props.consumer}
                />
              )}
            </div>
          </PreferencesProvider>
        )}
      </div>
    )
}

export default TailwindLayout

import React from 'react'
export default function YouTube (props) {
    return (
      <svg {...props} viewBox="0 0 24 24">
        <g fill="red">
          <path
            d="M9.729 14.59L9.728 8.41l5.944 3.101-5.944 3.08zM22.78 7.338s-.215-1.516-.875-2.184c-.836-.876-1.774-.88-2.204-.931C16.623 4 12.005 4 12.005 4h-.01S7.377 4 4.3 4.223c-.43.05-1.368.055-2.205.931-.66.668-.874 2.184-.874 2.184S1 9.118 1 10.898v1.67c0 1.78.22 3.56.22 3.56s.215 1.516.874 2.184c.837.876 1.937.849 2.426.94 1.76.17 7.48.222 7.48.222s4.623-.007 7.701-.23c.43-.052 1.368-.056 2.204-.932.66-.668.875-2.184.875-2.184s.22-1.78.22-3.56v-1.67c0-1.78-.22-3.56-.22-3.56z"
            transform="translate(-352 -839) translate(119 139) translate(0 640) translate(1 48) translate(220) translate(12 12)"
          />
        </g>
      </svg>
    )
  }
import React from 'react'
export default function Search (props) {
    return (
      <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M11.071 21.143c5.563 0 10.072-4.51 10.072-10.072C21.143 5.51 16.633 1 11.07 1 5.51 1 1 5.51 1 11.071c0 5.563 4.51 10.072 10.071 10.072z"
            fillRule="nonzero"
          />
          <path d="m22.429 22.429-4.23-4.23" />
        </g>
      </svg>
    )
  }
import React from 'react'

export default function ArrowSimple (props) {
    return (
      <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 3.818a.83.83 0 0 1 .231-.578.77.77 0 0 1 1.117 0L6 7.025 9.653 3.24a.769.769 0 0 1 1.116 0 .839.839 0 0 1 0 1.157L6.559 8.76a.77.77 0 0 1-1.117 0L1.23 4.397A.832.832 0 0 1 1 3.818"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    )
  }
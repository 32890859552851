import React from 'react'
import { ArrowButton } from '@truphone/buttons'
import Testimonial from './Testimonial'
import ContentHubItem from './ContentHubItem'
import '../style.css'

export default function MenuResources(props) {
  return (
    <div className="bg-white text-black relative overflow-hidden">
      <div className="container mx-auto px-6">
        <div className="flex justify-center">
          {props.sideMenu && (
            <div className="w-1/6 text-xs pt-6">
              <p className="mb-4 opacity-70 tracking-wide">{props.sideMenu.title}</p>
              {props.sideMenu.items &&
                props.sideMenu.items.map((link, i) => {
                  return (
                    <a
                      key={`resources-link-${i}`}
                      className="block mb-2 hover:text-blue-100"
                      target={link.target}
                      title={link.title}
                      rel={link.rel}
                      href={link.href}
                    >
                      {link.text}
                    </a>
                  )
                })}
            </div>
          )}

          {props.testimonials && (
            <div className="w-2/6 text-xs pt-6">
              <div className="relative z-1 flex flex-col h-full">
                <p className="mb-4 opacity-70 tracking-wide">{props.testimonials.title}</p>
                {props.testimonials.items && (
                  <div className="flex flex-wrap -m-2">
                    {props.testimonials.items.map((link, i) => {
                      return <Testimonial key={`testimonials-link-${i}`} {...link} />
                    })}
                  </div>
                )}
                {props.testimonials.seeAll && (
                  <div className="py-3 mt-auto">
                    <ArrowButton
                      href={props.testimonials.seeAll.href}
                      className="text-blue-100 text-sm block"
                    >
                      {props.testimonials.seeAll.text}
                    </ArrowButton>
                  </div>
                )}
              </div>
            </div>
          )}

          {props.contentHub && (
            <div className="w-1/2 text-xs relative bg-grey-100 bg-grey-after pt-6 pl-6">
              <div className="relative z-1 flex flex-col h-full">
                <p className="mb-4 opacity-70 tracking-wide">{props.contentHub.title}</p>
                <div className="flex">
                  {props.contentHub.links && (
                    <div className="w-1/3">
                      {props.contentHub.links.map((link, i) => {
                        return (
                          <a
                            key={`resources-link-${i}`}
                            className="block mb-2 hover:text-blue-100"
                            target={link.target}
                            title={link.title}
                            rel={link.rel}
                            href={link.href}
                          >
                            {link.text}
                          </a>
                        )
                      })}
                    </div>
                  )}

                  {props.contentHub.items && (
                    <div className="flex flex-wrap -m-2">
                      {props.contentHub.items.slice(0, 2).map((link, i) => {
                        return <ContentHubItem key={`content-hub-link-${i}`} {...link} />
                      })}
                    </div>
                  )}
                </div>
                {props.contentHub.seeAll && (
                  <div className="py-3 mt-auto">
                    <ArrowButton
                      href={props.contentHub.seeAll.href}
                      className="text-blue-100 text-sm block"
                    >
                      {props.contentHub.seeAll.text}
                    </ArrowButton>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

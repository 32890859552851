import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function WhiteButton({ className, children, ...props }) {
  className = cx(
    'text-base md:text-xl no-underline py-3 md:py-2 px-5 md:px-10  flex sm:inline-flex items-center justify-center bg-white text-black border-2 border-white rounded-lg focus:outline-none hover:bg-gray-100 hover:border-gray-100',
    className
  )
  return (
    <LinkWrap className={className} {...props}>
      {children}
    </LinkWrap>
  )
}

import React, { useState, useEffect } from 'react'
import '../style.css'
import { ClickOutside } from '@truphone/utils'
import { LinkWrap } from '@truphone/gatsby-wrappers'
import MenuProducts from './MenuProducts'
import MenuResources from './MenuResources'
import MenuCompany from './MenuCompany'
import MobileMenu from './MobileMenu'
import ModalComponent from './ModalComponent'
import Hamburger from './Hamburger'
import SecondaryMenu from './SecondaryMenu'

function DropdownComponent(props) {
  const [open, setOpen] = useState(false)

  return (
    <ClickOutside onClickOutside={() => setOpen(false)}>
      <div className="relative flex">
        <a
          className={`px-6 py-1 uppercase tracking-widest cursor-pointer whitespace-nowrap`}
          onClick={() => setOpen((open) => !open)}
        >
          {props.text}
        </a>
        {open && (
          <ul
            className={`absolute top-dropdown right-0 ${
              props.isConsumer ? 'bg-blue-100' : 'bg-blue-900'
            } z-10 py-2 rounded-sm`}
          >
            {props.items.map((item, i) => {
              return (
                <li key={`support-item-${i}`}>
                  <LinkWrap
                    href={item.href}
                    className="block min-w--150 tracking-normal whitespace-nowrap normal-case px-4 py-2 hover:bg-blue-300"
                  >
                    {item.text}
                  </LinkWrap>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </ClickOutside>
  )
}

export default function Menu(props) {
  const [active, setActive] = useState(null)
  const [open, setOpen] = useState(false)
  const [isOpenMobile, setOpenMobile] = useState(false)
  const [isOverlayOpen, setOverlayOpen] = useState(false)

  const selectedTab = !props.gated && props.tabs?.find((x) => x.type === 'personal')
  const isConsumer = props.consumer
  let resizeTimer

  const handleOpenMobile = () => {
    if (!isOpenMobile) {
      document.body.classList.add('h-screen')
      document.body.classList.add('fixed')
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('h-screen')
      document.body.classList.remove('fixed')
      document.body.classList.remove('overflow-hidden')
    }
    setOpenMobile((isOpenMobile) => !isOpenMobile)
  }

  const handleActive = (i) => {
    active === i ? setOpen((open) => !open) : setOpen(true)
    setActive(i)
  }

  useEffect(() => {
    const resizeEvent = () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(function () {
        if (window.innerWidth > 1280) {
          document.body.classList.remove('h-screen')
          document.body.classList.remove('fixed')
          document.body.classList.remove('overflow-hidden')
          setOpenMobile(false)
        } else {
          setOpen(false)
        }
      }, 250)
    }

    window.addEventListener('resize', resizeEvent)
    return () => {
      window.removeEventListener('resize', resizeEvent)
    }
  }, [])
  return (
    <>
      <div
        className={`${
          isOpenMobile ? 'fixed' : 'relative'
        } z-20 w-full flex justify-between md:pl-5 ${
          (isConsumer && !isOpenMobile) || props.hiddenSub ? 'bg-blue-100' : 'bg-blue-900'
        }  text-white text-base uppercase`}
      >
        {props.tabs && (
          <div className="flex w-full text-center text-sm sm:text-base tracking-tight sm:tracking-widest">
            {props.tabs.slice(1).map((tab, i) => {
              if (props.hiddenSub) {
                return (
                  <LinkWrap
                    key={`top-tab-${i}`}
                    className={`px-2 sm:px-2 py-1 minWdthMenu`}
                    href={props.page.banner[0].ctas[1].href}
                  >
                    {props.primaryButton.text}
                  </LinkWrap>
                )
              } else
                return i === 0 ? (
                  <LinkWrap
                    key={`top-tab-${i}`}
                    className={`w-1/2 md:w-auto px-2 sm:px-6 py-1 ${
                      isConsumer ? '' : 'bg-blue-300'
                    }`}
                    href={tab.link}
                  >
                    {tab.text}
                  </LinkWrap>
                ) : (
                  <LinkWrap
                    key={`top-tab-${i}`}
                    className={`w-1/2 md:w-auto px-6 py-1 ${
                      isConsumer && !isOpenMobile
                        ? 'bg-white text-black'
                        : isConsumer && isOpenMobile
                        ? 'bg-blue-300'
                        : 'bg-blue-900'
                    }`}
                    href={tab.link}
                  >
                    {tab.text}
                  </LinkWrap>
                )
            })}
          </div>
        )}

        <div
          className={`${
            props.hiddenSub
              ? 'inline-flex text-sm sm:text-base tracking-tight sm:tracking-widest'
              : 'hidden xl:flex'
          }  `}
        >
          {props.languages && (
            <DropdownComponent {...props.languages} isConsumer={isConsumer} mobile={isOpenMobile} />
          )}
          {props.support && (
            <DropdownComponent {...props.support} isConsumer={isConsumer} mobile={isOpenMobile} />
          )}
          {props.loginItem && props.loginItem.items ? (
            <DropdownComponent {...props.loginItem} isConsumer={isConsumer} mobile={isOpenMobile} />
          ) : (
            props.loginItem && (
              <LinkWrap
                data-login
                className={`px-6 py-1 uppercase tracking-widest cursor-pointer whitespace-nowrap`}
                href={props.loginItem.href}
              >
                {props.loginItem.text}
              </LinkWrap>
            )
          )}
        </div>
      </div>
      {props.hiddenSub ? (
        <></>
      ) : (
        <ClickOutside
          onClickOutside={() => {
            setOpen(false)
          }}
        >
          <div className={isOverlayOpen ? 'relative z-30' : ''}>
            <div
              className={`flex justify-between items-center h-16 px-4 ${
                isOpenMobile ? 'fixed top-6' : 'absolute'
              } z-10 w-full ${
                props.backgroundWhite || open || props.isTriggered
                  ? 'text-black bg-white'
                  : props.dark
                  ? 'text-black'
                  : 'text-white'
              }`}
            >
              <a
                className={`${isOpenMobile ? 'z-20' : ''} flex-shrink-0 mr-2`}
                href={
                  props.gated ? props.logoHref : props.tabs?.find((x) => x.selected)?.link || '#'
                }
              >
                {(props.dark || open || props.isTriggered) && !isOpenMobile ? (
                  <>{props.logo ? props.logo : <img src={props.logoUrl} alt="" />}</>
                ) : (
                  <>{props.logoWhite ? props.logoWhite : <img src={props.logoWhiteUrl} alt="" />}</>
                )}
              </a>

              {!props.gated && (
                <>
                  <div className={`ml-auto flex xl:hidden`}>
                    <button
                      className={`${isOpenMobile ? 'z-20' : ''}`}
                      onClick={() => handleOpenMobile()}
                    >
                      <Hamburger
                        dark={props.dark}
                        active={isOpenMobile}
                        isTriggered={props.isTriggered}
                      />
                    </button>

                    {isOpenMobile && (
                      <MobileMenu
                        setOverlayOpen={setOverlayOpen}
                        items={props.menuItems}
                        support={props.support}
                        overview={props.overview}
                        seeAllCta={props.seeAllCta}
                        languages={props.languages}
                        login={props.loginItem}
                        primaryButton={props.primaryButton}
                        isOverlayOpen={isOverlayOpen}
                        isConsumer={isConsumer}
                        downloadApp={props.downloadApp}
                        setOpen={() => handleOpenMobile()}
                      />
                    )}
                  </div>

                  {!props.gated && props.menuItems && (
                    <div className="hidden xl:flex">
                      {props.menuItems.map((menuItem, i) => {
                        if (!menuItem.text) {
                          return <></>
                        }
                        return (
                          <a
                            key={`nav-item-${i}`}
                            href={menuItem.href}
                            onClick={() => handleActive(menuItem.type)}
                            className={`cursor-pointer text-base uppercase tracking-widest m-2 font-medium rounded-lg p-2 hover:opacity-100 ${
                              props.dark || open
                                ? 'hover:bg-black hover:bg-opacity-5'
                                : 'hover:bg-grey-100 hover:bg-opacity-20'
                            } `}
                          >
                            {menuItem.text}
                          </a>
                        )
                      })}
                    </div>
                  )}
                </>
              )}

              {props.primaryButton && props.primaryButton.type === 'button' ? (
                <a
                  className="hidden xl:inline-flex items-center text-base bg-green-100 button-hover text-black font-normal py-1 h-9 px-4 rounded"
                  href={props.primaryButton.href}
                >
                  <span className="relative z-1">{props.primaryButton.text}</span>
                </a>
              ) : (
                props.primaryButton && (
                  <ModalComponent
                    text={props.primaryButton.text}
                    modal={{
                      ...props.primaryButton,
                      text: props.primaryButton.description
                    }}
                  />
                )
              )}
            </div>
            {!props.gated && (
              <div
                className={`hidden xl:block w-full absolute z-2 left-0 top-0 transform pt-24 ${
                  open
                    ? 'translate-y-0 pointer-events-auto'
                    : '-translate-y-full pointer-events-none'
                }`}
              >
                {active === 'products' && (
                  <MenuProducts {...props.menuItems.find((item) => item.type === 'products')} />
                )}
                {active === 'technology' && (
                  <MenuProducts {...props.menuItems.find((item) => item.type === 'technology')} />
                )}

                {active === 'resources' && (
                  <MenuResources {...props.menuItems.find((item) => item.type === 'resources')} />
                )}
                {active === 'company' && (
                  <MenuCompany {...props.menuItems.find((item) => item.type === 'company')} />
                )}
              </div>
            )}
          </div>
        </ClickOutside>
      )}

      {props.secondaryMenu && (
        <SecondaryMenu
          isTriggered={props.isTriggered}
          drawerOpen={open}
          {...props.secondaryMenu}
          dark={props.dark}
        />
      )}
    </>
  )
}

import React from 'react'

export default function Tick (props) {
    return (
      <svg {...props} width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m3 9.5 3.49 3.6c1.644-4.72 3.011-6.79 6.11-9.6"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
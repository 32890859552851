import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import '../style.css'
import MenuDropdown from './MenuDropdown'
import { ArrowSimple } from '@truphone/icons'

function SecondaryItem(props) {
  const [visible, setVisible] = useState(props.open)
  const referenceRef = useRef(null)
  const popperRef = useRef(null)
  const { styles, attributes, update } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: 0
        }
      }
    ]
  })
  function handleDropdownClick(event) {
    if (props.handleClick) {
      props.handleClick(event)
    }
    setVisible(!visible)
    update()
  }
  return (
    <div className={` ${props.dark ? 'hover:bg-gray-200/20' : 'hover:bg-white/10'}`}>
      <div
        aria-controls="secondary-menu"
        onClick={handleDropdownClick}
        ref={referenceRef}
        aria-haspopup="true"
        className={`p-3 cursor-pointer flex ${
          props.isSelected ? `border-b-2 ${props.dark ? 'border-black' : 'border-white'}` : ''
        }`}
      >
        <p className="text-sm">{props.title}</p>
        {props.list && props.list.length > 0 && <ArrowSimple className="ml-2 w-3 h-5" />}
      </div>
      {props.list && props.list.length > 0 && (
        <div
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          className={`z-20 ${props.open ? 'block' : 'hidden'} `}
          role={undefined}
        >
          <MenuDropdown list={props.list} />
        </div>
      )}
    </div>
  )
}

export default SecondaryItem

import React from 'react'
export default function LinkedIn (props) {
    return (
      <svg {...props} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <g fill="#0077B5">
            <path
              d="M19.748 19.746h-3.263v-5.105c0-1.218-.021-2.784-1.696-2.784-1.698 0-1.957 1.327-1.957 2.697v5.192h-3.26V9.248h3.129v1.435h.045c.435-.826 1.5-1.696 3.087-1.696 3.304 0 3.915 2.174 3.915 5.002v5.757zM5.894 7.813C4.846 7.813 4 6.966 4 5.921s.845-1.892 1.893-1.892c1.044 0 1.89.847 1.89 1.892s-.846 1.892-1.89 1.892zM4.26 19.746h3.266V9.248H4.26v10.498zM21.373 1H2.623C1.727 1 1 1.71 1 2.586v18.826C1 22.29 1.727 23 2.623 23h18.75C22.27 23 23 22.289 23 21.412V2.586C23 1.71 22.27 1 21.373 1z"
              transform="translate(-132 -839) translate(119 139) translate(0 640) translate(1 48) translate(12 12)"
            />
          </g>
        </g>
      </svg>
    )
  }
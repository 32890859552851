import React, { useState } from 'react'
import { Accordion } from '@truphone/accordion'
import { ClickOutside } from '@truphone/utils'
import { Medium, Facebook, Twitter, YouTube, Vimeo, LinkedIn, Instagram } from '@truphone/icons'
import '../style.css'

function Footer(props) {
  const [isOpen, setOpen] = useState(false)
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleChange = (panel) => {
    setExpanded(panel !== expanded ? panel : false)
  }
  return (
    <div
      className={`${
        props.consumer
          ? 'bg-blue-100 text-white'
          : props.business
          ? 'bg-blue-900 text-white'
          : 'bg-navy text-white'
      } pt-10 pb-6`}
    >
      <div className="container px-6 mx-auto">
        <div className={`hidden lg:block`}>
          <div className="flex">
            {props.gated ? (
              <>
                {props.logo && <div className="mx-auto mb-3 max-w--107">{props.logo}</div>}
                {props.logoUrl && (
                  <img
                    className="mx-auto mb-4 max-w--107"
                    src={props.logoUrl}
                    loading="lazy"
                    alt="truphopne"
                  />
                )}
              </>
            ) : (
              <div className="mx-3 grid-1/2 sm:grid-1/3 md:grid-2/12">
                <div className="mb-3 max-w--107">{props.logo}</div>
                <div className="relative whitespace-nowrap">
                  <button
                    className="text-sm p-2 -mx-2 text-current uppercase font-bold tracking-wider border border-white border-opacity-0 hover:border-opacity-30"
                    onClick={handleOpen}
                  >
                    {props.languages ? props.languages.find((x) => x.selected)?.text : 'English'}
                    <svg
                      className="text-sm ml-2 inline-block "
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      fill="currentColor"
                      style={{ width: 12, height: 24 }}
                    >
                      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
                    </svg>
                  </button>

                  <ClickOutside onClickOutside={() => handleClose()}>
                    {props.languages &&
                      isOpen &&
                      props.languages.map((lang, i) => {
                        return (
                          <a
                            key={`footer-lang-${i}`}
                            className="block p-2 -mx-2 bg-white text-navy hover:bg-gray-100"
                            href={lang.href}
                          >
                            {lang.text}
                          </a>
                        )
                      })}
                  </ClickOutside>
                </div>
              </div>
            )}

            {props.list &&
              props.list.map((item, i) => {
                return (
                  <div className="mx-3 grid-1/2 sm:grid-1/3 md:grid-2/12" key={`footer${i}`}>
                    <h6 className="text-base uppercase font-bold tracking-wider">{item.title}</h6>

                    {item.children &&
                      item.children.map((child, i) => {
                        return (
                          <a
                            className="block opacity-60 hover:opacity-100 text-[13px] text-current hover:text-current my-3"
                            href={child.href}
                            title={child.title}
                            target={child.newTab ? '_blank' : null}
                            key={`footer-item${i}`}
                          >
                            {child.text}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
          </div>
        </div>
        <div className="block lg:hidden">
          {props.list &&
            props.list.map((item, i) => {
              return (
                <Accordion
                  title={item.title}
                  footer
                  key={`footer-panel${i}`}
                  expanded={expanded === `footer-panel${i}`}
                  onChange={() => handleChange(`footer-panel${i}`)}
                >
                  <div className="p-2 pt-0">
                    {item.children &&
                      item.children.map((child, i) => {
                        return (
                          <a
                            className="block opacity-60 hover:opacity-100 text-[13px] text-current hover:text-current my-3"
                            href={child.href}
                            title={child.title}
                            target={child.newTab ? '_blank' : null}
                            key={`footer-item${i}`}
                          >
                            {child.text}
                          </a>
                        )
                      })}
                  </div>
                </Accordion>
              )
            })}
          <div className={`${props.gated ? '' : 'mt-14'}`}>
            {props.logo && <div className="mx-auto mb-3 max-w--107">{props.logo}</div>}
            {props.logoUrl && (
              <img
                className="mx-auto mb-4 max-w--107"
                src={props.logoUrl}
                alt="truphopne"
                loading="lazy"
              />
            )}
            {props.languages && (
              <Accordion
                title={props.languages ? props.languages.find((x) => x.selected)?.text : 'English'}
                footer
                languages
                key={`footer-language`}
                expanded={isOpenMobile}
                onChange={() => setIsOpenMobile(!isOpenMobile)}
              >
                {props.languages.map((lang, i) => {
                  return (
                    <a
                      className="block opacity-60 hover:opacity-100 text-[13px] text-current hover:text-current my-3"
                      href={lang.href}
                      title={lang.title}
                      target={lang.newTab ? '_blank' : null}
                      key={`language-${i}`}
                    >
                      {lang.text}
                    </a>
                  )
                })}
              </Accordion>
            )}
          </div>
        </div>

        {props.gated ? (
          <div className="text-center mx-auto text-xs opacity-60 tracking-wide">
            © {new Date().getFullYear()} {props.copyright}
          </div>
        ) : (
          <div className="flex flex-wrap justify-between w-full mt-16">
            <div className="text-center md:text-left w-full md:w-1/2 text-xs opacity-60 tracking-wide">
              © {new Date().getFullYear()} {props.copyright}
            </div>
            <div className="mt-5 md:mt-0 w-full md:w-1/2 flex justify-center md:justify-end social-icons">
              {/* <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://www.facebook.com/truphone"
                title="Facebook"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <Facebook />
              </a> */}
              {/* <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://twitter.com/truphone"
                title="Twitter"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <Twitter />
              </a> */}
              {/* <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://medium.com/@Truphone"
                title="Medium"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <Medium />
              </a> */}
              <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://www.linkedin.com/company/truphone"
                title="LinkedIn"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <LinkedIn />
              </a>
              <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://www.youtube.com/user/truphone"
                title="YouTube"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <YouTube />
              </a>
              <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://vimeo.com/truphone"
                title="Vimeo"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <Vimeo />
              </a>
              {/* <a
                className="w-7 m-2 opacity-40 hover:opacity-100"
                href="https://www.instagram.com/mytruphone"
                title="Instagram"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <Instagram />
              </a> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer

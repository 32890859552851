import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '@truphone/buttons'
import { uppercaseString } from '@truphone/utils'
import '../style.css'
import MobileList from './MobileList'
import OverlayComponent from './OverlayComponent'

export default function MobileMenu(props) {
  const [active, setActive] = useState(null)
  const [mainNavVisible, setMainNavVisible] = useState(true)

  const handleActive = (i) => {
    setActive(i)
    setMainNavVisible((mainNavVisible) => !mainNavVisible)
  }

  const productsMenuItem = props.items?.find((x) => x.type === 'products')
  const technologyMenuItems = props.items?.find((x) => x.type === 'technology')
  const resourcesMenuItem = props.items?.find((x) => x.type === 'resources')
  const companyMenuItem = props.items?.find((x) => x.type === 'company')
  const supportMenuItem = props.support
  return (
    <div
      className={`fixed ${
        props.isOverlayOpen ? 'z-30' : ''
      } top-0 left-0 h-full w-full text-white bg-blue-300`}
    >
      <div className="flex flex-col overflow-hidden h-full">
        <div className="mt-28 relative overflow-y-auto h-full">
          <ul
            className={`${
              !mainNavVisible ? 'opacity-0 -translate-x-full' : 'opacity-100'
            } transition transform relative left-0 top-0 max-w-sm mx-auto px-4`}
          >
            {props.items?.map((item, i) => {
              return (
                <li key={`mobile-item-${i}`} className={``}>
                  <a
                    href={item.href ? item.href : null}
                    onClick={() => (!item.href ? handleActive(item.type) : null)}
                    className="heron text-6xl text-6xl-resp tracking-medium block mb-4 cursor-pointer"
                  >
                    {uppercaseString(item.text)}
                  </a>
                </li>
              )
            })}
            {props.support && (
              <li>
                <a
                  onClick={() => handleActive(props.support.type)}
                  className="heron text-6xl text-6xl-resp tracking-medium block mb-4 cursor-pointer"
                >
                  {uppercaseString(props.support.text)}
                </a>
              </li>
            )}
          </ul>
          {productsMenuItem && (
            <MobileList
              setOpen={props.setOpen}
              overview={props.overview}
              backButton={productsMenuItem.text}
              goBack={() => handleActive(null)}
              active={active === productsMenuItem.type}
              items={productsMenuItem.items}
            />
          )}
          {technologyMenuItems && (
            <MobileList
              setOpen={props.setOpen}
              backButton={technologyMenuItems.text}
              goBack={() => handleActive(null)}
              active={active === technologyMenuItems.type}
              items={technologyMenuItems.items}
            />
          )}
          {resourcesMenuItem && (
            <MobileList
              setOpen={props.setOpen}
              backButton={resourcesMenuItem.text}
              goBack={() => handleActive(null)}
              active={active === resourcesMenuItem.type}
              items={[
                {
                  title: resourcesMenuItem.sideMenu.title,
                  items: resourcesMenuItem.sideMenu.items
                },
                {
                  title: resourcesMenuItem.testimonials.title,
                  scrolledList: true,
                  testimonials: true,
                  items: resourcesMenuItem.testimonials.items,
                  seeAll: resourcesMenuItem.testimonials.seeAll
                },
                {
                  title: resourcesMenuItem.contentHub.title,
                  links: resourcesMenuItem.contentHub.links,
                  scrolledList: true,
                  items: resourcesMenuItem.contentHub.items,
                  seeAll: resourcesMenuItem.contentHub.seeAll
                }
              ]}
            />
          )}
          {companyMenuItem && (
            <MobileList
              setOpen={props.setOpen}
              backButton={companyMenuItem.text}
              goBack={() => handleActive(null)}
              active={active === companyMenuItem.type}
              items={[
                {
                  title: companyMenuItem.sideMenu.title,
                  items: companyMenuItem.sideMenu.items
                },
                {
                  title: companyMenuItem.newsroom.title,
                  scrolledList: true,
                  newsAndPress: true,
                  items: companyMenuItem.newsroom.items,
                  seeAll: companyMenuItem.newsroom.seeAll
                },
                {
                  title: companyMenuItem.events.title,
                  scrolledList: true,
                  items: companyMenuItem.events.items,
                  seeAll: companyMenuItem.events.seeAll
                }
              ]}
            />
          )}
          {supportMenuItem && (
            <MobileList
              setOpen={props.setOpen}
              overview={props.overview}
              backButton={supportMenuItem.text}
              goBack={() => handleActive(null)}
              active={active === supportMenuItem.type}
              items={supportMenuItem.items}
            />
          )}
        </div>
        <div className="bg-blue-900 w-full mt-auto">
          {props.languages && (
            <OverlayComponent
              setOverlayOpen={props.setOverlayOpen}
              text={props.languages.items.find((x) => x.selected)?.text}
              items={props.languages.items}
            />
          )}
          <div className="flex items-center justify-between p-2">
            {props.login && props.login.items ? (
              <OverlayComponent
                primary
                setOverlayOpen={props.setOverlayOpen}
                text={props.login.text}
                items={props.login.items}
              />
            ) : (
              props.login && (
                <PrimaryButton
                  className="whitespace-nowrap flex-grow cursor-pointer m-2"
                  href={props.login.href}
                  data-login
                >
                  {props.login.text}
                </PrimaryButton>
              )
            )}

            {props.primaryButton && (
              <>
                {props.primaryButton.type === 'button' ? (
                  <SecondaryButton
                    targer={props.primaryButton.openTab ? '_blank' : ''}
                    className="flex-grow cursor-pointer m-2"
                    href={props.primaryButton.href}
                  >
                    {props.primaryButton.text}
                  </SecondaryButton>
                ) : (
                  <OverlayComponent
                    secondary
                    setOverlayOpen={props.setOverlayOpen}
                    text={props.primaryButton.text}
                    modal={{
                      ...props.primaryButton,
                      text: props.primaryButton.description
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

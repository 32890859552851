import React from 'react'

export default function Close (props) {
    const { opacity, ...rest } = props
    return (
      <svg {...rest} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.949 15.536a.999.999 0 1 1-1.414 1.414L12 13.414 8.465 16.95a1 1 0 1 1-1.415-1.414L10.586 12 7.05 8.465a.999.999 0 1 1 1.414-1.414L12 10.586l3.535-3.535a.999.999 0 1 1 1.414 1.414L13.414 12l3.535 3.536zM12 1C5.925 1 1 5.925 1 12c0 6.076 4.925 11 11 11s11-4.924 11-11c0-6.075-4.925-11-11-11z"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity={opacity || '0.6'}
          opacity={opacity || '0.6'}
        />
      </svg>
    )
  }
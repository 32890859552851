import React from 'react'
import { Link } from 'gatsby'
import { isRelativeUrl } from './utils/urlUtils'

export default function LinkWrap(props) {
  if (props.href && !props.href.startsWith('#') && isRelativeUrl(props.href) && !props.target) {
    return (
      <Link
        className={props.className}
        to={props.href}
        aria-controls={props['aria-controls']}
        onClick={props.onClick}
        aria-haspopup={props['aria-haspopup']}
        target={props.target}
        title={props.title}
        rel={props.rel}
      >
        {props.children}
      </Link>
    )
  } else {
    return (
      <a
        className={props.className}
        href={props.href}
        aria-controls={props['aria-controls']}
        onClick={props.onClick}
        aria-haspopup={props['aria-haspopup']}
        target={props.target}
        rel={props.rel}
        title={props.title}
      >
        {props.children}
      </a>
    )
  }
}

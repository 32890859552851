import React, { useState, useEffect, useRef } from 'react'
import { OutlinedButton, PrimaryButton } from '@truphone/buttons'
import Cookies from 'universal-cookie'

const RestrictiveGdprCountries = ['DE']

let cookies = new Cookies()

function WeUseCookies(props) {
  const handleCookiePreferences = (e) => {
    const el = e.target.closest('A')
    if (el && e.currentTarget.contains(el) && el.href.indexOf('#') > 0) {
      e.preventDefault()
      if (typeof props.showPreferences === 'function') {
        props.showPreferences()
      }
    }
  }

  return (
    <div className={`${props.show ? 'block' : 'hidden'}`}>
      <div className="border-b border-grey-200 px-6 py-3">
        <h4 className="text-xl">{props.title}</h4>
      </div>
      <div
        className={`py-2 px-6 md:py-4 tracking-wide text-sm md:text-base link-style-color ${
          props.hide ? 'hidden' : 'block'
        }`}
        dangerouslySetInnerHTML={{ __html: props.content }}
        onClick={(e) => handleCookiePreferences(e)}
      />
    </div>
  )
}

function PreferencesList(props) {
  return (
    <div className={`${props.show ? 'block' : 'hidden'}`}>
      <div className="border-t border-b border-grey-200 px-6 py-3">
        <h4 className="text-xl">{props.title}</h4>
      </div>
      {props.preferences &&
        props.preferences.map((option, i) => {
          const ref = useRef()
          return (
            <label
              key={`cookie-preferences${i}`}
              className={`w-full inline-flex items-center hover:bg-grey-100 ${
                i === 0 ? 'opacity-60' : 'border-t cursor-pointer'
              } border-grey-200 p-3`}
              onClick={() => (ref.current.value = !ref.current.value)}
            >
              <input
                ref={ref}
                className={`form-checkbox focus:border-blue-100 ${
                  i === 0 ? 'text-black opacity-30' : 'text-blue-100'
                } border-2 border-grey-300 flex-shrink-0 rounded-sm h-5 w-5`}
                name={option.title}
                id={option.id}
                defaultChecked={props.selected.indexOf(option.id) !== -1}
                disabled={i === 0}
                type="checkbox"
                onChange={() =>
                  i !== 0 ? props.togglePreference && props.togglePreference(option.id) : null
                }
              />
              <div htmlFor={option.id} className={`ml-4 ${i === 0 ? '' : 'pointer-events-none'}`}>
                <h5 className="text-sm">{option.title}</h5>
                <p className="text-xs opacity-60 tracking-wide">{option.description}</p>
              </div>
            </label>
          )
        })}
    </div>
  )
}

export default function CookiePrompt(props) {
  const [open, setOpen] = useState(props.open || false)
  const [showPreferences, setShowPreferences] = useState(false)
  const [restrictiveMode, setRestrictiveMode] = useState(false)
  const [checked, setChecked] = useState(['Essential'])

  useEffect(() => {
    if (!props.locale) {
      return
    }

    let cookiePolicy = cookies.get('cookie_preferences')
    if (cookiePolicy || props.hide) {
      return
    }

    if (RestrictiveGdprCountries.includes(props.locale)) {
      setRestrictiveMode(true)
    }

    let page = document.querySelector('#page')
    let pageMain = document.querySelector('.page-main')

    if (!cookiePolicy && !props.hide) {
      setTimeout(() => {
        setOpen(true)
        if (page) {
          page.style.transition = 'filter .5s'
          page.style.filter = 'blur(18px)'
        }
        if (pageMain) {
          pageMain.style.transition = 'filter .5s'
          pageMain.style.filter = 'blur(18px)'
        }
      }, 500)
    }
  }, [props.locale])

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const acceptAll = () => {
    handleClose(props.preferences.map((x) => x.id))
  }

  const handleClose = (preferences) => {
    setOpen(false)

    let page = document.querySelector('#page')
    let pageMain = document.querySelector('.page-main')
    if (page) {
      page.style.filter = 'unset'
    }

    if (pageMain) {
      pageMain.style.filter = 'unset'
    }

    let expiry = new Date()
    expiry.setFullYear(expiry.getFullYear() + 1)
    cookies.set('cookie_preferences', preferences.toString().replace(/,/g, '-'), {
      path: '/',
      expires: expiry,
      domain: window.location.host.endsWith('.truphone.com') ? '.truphone.com' : null
    })

    if (props.onCookiePreferencesConfirmed) {
      props.onCookiePreferencesConfirmed(preferences)
    }
  }

  return (
    open && (
      <div className="fixed w-full h-full bg-black bg-opacity-50 z-50">
        <div className="flex items-center justify-center w-screen md:w-full h-full lg:px-4 overflow-y-auto">
          <div className="max-w-lg md:min-w--250 bg-white md:rounded-sm mx-auto">
            <WeUseCookies
              title={props.title}
              content={props.content}
              show={restrictiveMode || !showPreferences}
              showPreferences={() => setShowPreferences(true)}
            />

            <PreferencesList
              title={props.preferenceTitle}
              preferences={props.preferences}
              show={restrictiveMode || showPreferences}
              selected={checked}
              togglePreference={(option) => handleToggle(option)}
            />

            <div className="border-t border-grey-200 px-6 py-3 flex flex-col">
              {!showPreferences && !restrictiveMode && (
                <OutlinedButton
                  className="text-sm md:text-base cursor-pointer mb-2"
                  onClick={() => setShowPreferences(true)}
                >
                  {props.manageText}
                </OutlinedButton>
              )}

              <PrimaryButton className="text-sm md:text-base cursor-pointer" onClick={acceptAll}>
                {props.acceptText}
              </PrimaryButton>

              {showPreferences && !restrictiveMode && (
                <OutlinedButton
                  className="text-sm md:text-base cursor-pointer mt-2"
                  onClick={() => handleClose(checked)}
                >
                  {props.saveSelectionText}
                </OutlinedButton>
              )}

              {restrictiveMode && (
                <PrimaryButton
                  className="text-sm md:text-base cursor-pointer mt-2"
                  onClick={() => handleClose(checked)}
                >
                  {props.saveSelectionText}
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

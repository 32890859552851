import Svg from 'react-inlinesvg'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

function uppercaseString(string) {
  return string
    ? string
        .toUpperCase()
        .replace('ESIM', 'eSIM')
        .replace('ISIM', 'iSIM')
        .replace('IOT', 'IoT')
        .replace('IPAD', 'iPAD')
        .replace('IPHONE', 'iPHONE')
        .replace('MIFID', 'MiFID')
        .replace('WIFI', 'WiFi')
        .replace('IBAHN', 'iBAHN')
    : null
}

const formatDate = (dateString, locale) => {
  return new Date(dateString).toLocaleDateString(
    locale, //Take the browser default language.
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }
  )
}
const placeImage = (
  image,
  options = { svg: false, loading: null, className: null, appStore: null }
) => {
  const { svg, loading, className, appStore } = options
  return image.gatsbyImageData !== null ? (
    <GatsbyImage image={image.gatsbyImageData} loading={loading} data-app-store={appStore} />
  ) : svg ? (
    <Svg src={image.url} data-app-store={appStore} />
  ) : (
    <img className={className} src={image.url} alt="" data-app-store={appStore} />
  )
}

function mapSecondaryMenu(secondaryMenu, url) {
  console.log(url)
  return (
    secondaryMenu && {
      text: secondaryMenu.label,
      href: secondaryMenu.url,
      iconUrl: secondaryMenu.icon.url,
      invertedIconUrl: secondaryMenu.iconWhite?.url,
      items: secondaryMenu.items.map((x) => ({
        text: x.label,
        href: x.url,
        selected: x.url === url || x.items.find((y) => y.url === url),
        target: x.openNewTab && '_blank',
        outsideLink: x.showOutsideLinkIcon,
        items: x.items.map((y) => ({
          text: y.label,
          href: y.url,
          selected: y.url === url,
          target: y.openNewTab && '_blank'
        }))
      }))
    }
  )
}

function detectIEEdge() {
  var ua = window.navigator.userAgent
  console.log(ua)

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  var edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

export { uppercaseString, placeImage, mapSecondaryMenu, detectIEEdge, formatDate }

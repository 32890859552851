import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function OutlinedButton({ className, children, ...props }) {
    className = cx(
      'text-base md:text-xl font-medium py-3 md:py-2 px-5 inline-flex items-center justify-center text-black border-gray-200 border-2 rounded-lg leading-tight hover:bg-gray-100 focus:outline-none',
      className
    )
    return (
      <LinkWrap className={className} {...props}>
        {children}
      </LinkWrap>
    )
  }
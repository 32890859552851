import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'
import { Arrow} from '@truphone/icons'

export default function ArrowButton({ className, children, ...props }) {
    className = cx('text-base arrow-link relative flex items-center', className)
    return (
      <LinkWrap className={className} {...props}>
        <span>{children}</span>
        <Arrow className="-rotate-90 ml-2 w-4 h-6 arrow" />
      </LinkWrap>
    )
  }
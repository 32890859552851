import React from 'react'

export default function ArrowLinkOutSide (props) {
    return (
      <svg {...props} width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.504 2.22H5.046a.496.496 0 0 0-.496.496v.496c0 .274.222.497.496.497H7.86c.221 0 .332.267.175.423l-6.39 6.39a.497.497 0 0 0 0 .702l.351.35a.497.497 0 0 0 .702 0l6.39-6.39a.248.248 0 0 1 .423.176v2.814c0 .274.222.496.497.496h.496A.496.496 0 0 0 11 8.174V2.716a.496.496 0 0 0-.496-.496"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    )
  }
import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function SecondaryButton({ className, children, component, ...props }) {
  className = cx(
    'text-base md:text-xl font-medium no-underline py-3 md:py-2 px-5 md:px-10 flex sm:inline-flex items-center text-black justify-center leading-tight rounded-lg bg-green-100 border-2 border-green-100 hover:bg-green-50 hover:border-green-50',
    className
  )

  if (component == 'button') {
    return (
      <button className={className} {...props}>
        {children}
      </button>
    )
  }
  return (
    <LinkWrap className={className} {...props}>
      <span className="relative z-1">{children}</span>
    </LinkWrap>
  )
}

import React, { useState } from 'react'
import {  PrimaryButton, SecondaryButton } from '@truphone/buttons'
import { ArrowSimple } from '@truphone/icons'
export default function OverlayComponent(props) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
    props.setOverlayOpen(!open)
  }

  return (
    <>
      {props.primary ? (
        <PrimaryButton className="flex-grow cursor-pointer m-2" onClick={handleOpen}>
          {props.text}
        </PrimaryButton>
      ) : props.secondary ? (
        <SecondaryButton className="flex-grow cursor-pointer m-2" onClick={handleOpen}>
          {props.text}
        </SecondaryButton>
      ) : (
        <a
          className={`border-b border-white border-opacity-30 flex justify-between items-center text-white font-bold px-6 py-4 uppercase tracking-xwidest cursor-pointer`}
          onClick={handleOpen}
        >
          {props.text}
          <ArrowSimple className={`w-4 transform rotate-180`} />
        </a>
      )}

      {open && (
        <div className="absolute top-0 left-0 w-full h-full bg-blue-900 z-30 ">
          <div className="flex">
            <button className="ml-auto p-4 cursor-pointer" onClick={handleOpen}>
              <ArrowSimple className={`w-5 `} />
            </button>
          </div>

          {props.items && (
            <ul className="text-white">
              {props.items.map((item, i) => {
                return (
                  <li key={`overlay-item-${i}`}>
                    <a
                      href={item.href}
                      className="text-base block normal-case px-4 py-3 hover:bg-blue-300"
                    >
                      {item.text}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}

          {props.modal && (
            <div className="text-white h-full w-full flex flex-col items-center justify-center -mt-14">
              <div className="text-center">
                <h5 className="text-2xl">{props.modal.title}</h5>
                <p className="text-sm opacity-60 tracking-wide">{props.modal.text}</p>
              </div>
              <div className="flex flex-col justify-center items-center py-12 px-4">
                {props.modal.appStore && (
                  <a
                    data-app-store="Apple"
                    href={props.modal.appStore.href}
                    className="block w-56 m-2"
                  >
                    <img src={props.modal.appStore.imageWhite} alt="appstore" />
                  </a>
                )}
                {props.modal.playStore && (
                  <a
                    data-app-store="Google"
                    href={props.modal.playStore.href}
                    className="block w-56 m-2"
                  >
                    <img src={props.modal.playStore.imageWhite} alt="playstore" />
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

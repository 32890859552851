import React, { useState, useEffect } from 'react'
import { uppercaseString, ClickOutside } from '@truphone/utils'
import { ArrowLinkOutSide } from '@truphone/icons'
import '../style.css'
import {LinkWrap} from '@truphone/gatsby-wrappers'
import SecondaryItem from './SecondaryItem'

function SecondaryMenu(props) {
  const [isSelected, setSelected] = useState(-1)
  const [active, setActive] = useState(null)
  const [anchorRef, setAnchorRef] = useState(false)

  useEffect(() => {
    setSelected(props.items && props.items.indexOf(props.items.find((item) => item.selected)))
  }, [props.items])

  const handleOpen = (event, id) => {
    if (active === id) {
      setActive(null)
      setAnchorRef(null)
    } else {
      setAnchorRef(event.target)
      setActive(id)
    }
    setSelected(id)
  }

  const handleClose = () => {
    setActive(null)
    setAnchorRef(null)
    setSelected(props.items && props.items.indexOf(props.items.find((item) => item.selected)))
  }

  return (
    <div className={`${props.isTriggered || props.drawerOpen ? '' : 'z-2'} relative`}>
      <ClickOutside onClickOutside={handleClose}>
        <div
          className={`absolute top-15 left-0 w-full z-1 ${props.isTriggered ? 'bg-white' : ''} ${
            props.isTriggered || props.dark ? 'text-black' : 'text-white'
          }`}
        >
          <div className="container mx-auto px-4">
            <div
              className={`border-b border-opacity-20 ${
                props.isTriggered || props.dark ? 'border-grey-200' : 'border-white'
              }`}
            >
              <div className="styled-scroll flex flex-nowrap">
                <div className="flex-shrink-0">
                  <LinkWrap className="inline-flex text-current no-underline " href={props.href}>
                    <div className="flex items-center whitespace-nowrap my-2">
                      {!props.isTriggered && !props.dark && props.invertedIconUrl ? (
                        <img
                          className={'relative w-6 h-6 mr-2'}
                          src={props.invertedIconUrl}
                          alt={props.iconAlt}
                        />
                      ) : (
                        props.iconUrl && (
                          <img
                            className={'relative h-6 mr-2 w-6'}
                            src={props.iconUrl}
                            alt={props.iconAlt}
                          />
                        )
                      )}

                      <h4 className="font-bold text-sm tracking-wider">
                        {uppercaseString(props.text)}
                      </h4>
                    </div>
                  </LinkWrap>
                </div>

                <div
                  className="ml-auto flex flex-nowrap whitespace-nowrap"
                  aria-label="secondary-nav"
                >
                  {props.items.map((menuItem, i) => {
                    return menuItem.items && menuItem.items.length > 0 ? (
                      <SecondaryItem
                        dark={props.isTriggered || props.dark ? 'true' : undefined}
                        key={`secondary-item-${i}`}
                        handleClose={handleClose}
                        list={menuItem.items}
                        title={menuItem.text}
                        anchorRef={anchorRef}
                        item={i}
                        open={active === i}
                        isSelected={isSelected === i}
                        handleClick={(e) => handleOpen(e, i)}
                      />
                    ) : (
                      <LinkWrap
                        className={`${
                          isSelected === i
                            ? ` ${
                                props.isTriggered || props.dark ? 'border-black' : 'border-white'
                              }`
                            : 'border-transparent'
                        } border-b-2 inline-flex items-center text-sm w-auto  p-3 text-current hover:text-current ${
                          props.dark ? 'hover:bg-gray-200/20' : 'hover:bg-white/10'
                        }`}
                        href={menuItem.href}
                        key={`item-${i}`}
                        aria-controls="secondary-menu"
                        onClick={props.handleClick}
                        aria-haspopup="true"
                        target={menuItem.outsideLink ? '_blank' : ''}
                      >
                        <h5 className="text-sm">{menuItem.text} </h5>
                        {menuItem.outsideLink && (
                          <ArrowLinkOutSide className="w-3 ml-1" alt="outside link" />
                        )}
                      </LinkWrap>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClickOutside>
    </div>
  )
}

export default SecondaryMenu

import React from 'react'
import { ArrowButton } from '@truphone/buttons'
import '../style.css'

export default function MenuProducts(props) {
  return (
    <div className="bg-white text-black pt-6 relative">
      <div className={`container mx-auto px-6 ${props.seeAll ? '' : 'pb-6'}`}>
        <div className="flex justify-start flex-wrap">
          {props.items.map((menuItem, i) => {
            return (
              <a
                href={menuItem.href}
                className={`flex items-start p-3 hover:text-blue-100 hover-black  ${
                  props.items.length > 4 && props.items.length < 7 ? 'w-1/3' : 'w-1/4'
                }`}
                key={`menu-item-${i}`}
              >
                <img className="w-9 flex-shrink-0" src={menuItem.imgUrl} alt="" />
                <div className="ml-4">
                  <div className="text-sm font-semibold tracking-wide mb-2 border-b border-blue-100 border-opacity-50 hover:border-opacity-100 inline-block">
                    {menuItem.title}
                  </div>
                  <p className="text-xs opacity-60 tracking-wide">{menuItem.subtitle}</p>
                </div>
              </a>
            )
          })}
        </div>
        {props.seeAll && (
          <div className="border-t border-grey-200 flex py-3">
            <ArrowButton href={props.seeAll.href} className="text-blue-100 text-sm block ml-auto">
              {props.seeAll.text}
            </ArrowButton>
          </div>
        )}
      </div>
    </div>
  )
}

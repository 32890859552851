import React, { useEffect, useReducer } from 'react'
import Cookies from 'universal-cookie'

export const PreferencesContext = React.createContext()

const PreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_PREFERENCES':
          state.essential = action.payload.essential
          state.analytical = action.payload.analytical
          state.marketing = action.payload.marketing

          return { ...state }
      }
    },
    { essential: null, marketing: null, analytical: null }
  )

  const setPreferences = (preferences) => {
    dispatch({
      type: 'SET_PREFERENCES',
      payload: {
        essential: preferences?.includes('Essential'),
        analytical: preferences?.includes('Analytical'),
        marketing: preferences?.includes('Marketing')
      }
    })
  }

  useEffect(() => {
    const cookies = new Cookies()
    const cookiePreferences = cookies.get('cookie_preferences')

    setPreferences(cookiePreferences)
  }, [])

  return (
    <PreferencesContext.Provider
      value={{
        setPreferences,
        ...state
      }}
    >
      {children}
    </PreferencesContext.Provider>
  )
}

export default PreferencesProvider

import React from 'react'
import cx from 'classnames'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function PrimaryButton({ className, children, component, ...props }) {
  className = cx(
    'text-base md:text-xl font-medium py-3 md:py-2 px-5 md:px-10 inline-flex items-center justify-center bg-blue-100 hover:bg-blue-700 border-2 border-blue-100 hover:border-blue-700 leading-tight text-white rounded-lg focus:outline-none',
    className
  )

  if (component == 'button') {
    return (
      <button className={className} {...props}>
        {children}
      </button>
    )
  }

  return (
    <LinkWrap className={className} {...props}>
      {children}
    </LinkWrap>
  )
}

import React, { useEffect, useReducer } from 'react'
import { geoLookup } from '@truphone/utils'

export const LocaleContext = React.createContext()

const LocaleProvider = ({ currencies, children }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'INITIAL_STATE':
          if (currencies && !currencies.includes(action.payload.currency)) {
            action.payload.currency = 'EUR'
          }
          return { ...action.payload }
        case 'SET_CURRENCY':
          if (!currencies || currencies.includes(action.payload)) {
            state.currency = action.payload
            return { ...state }
          }

          return state
      }
    },
    { iso: 'GB', currency: 'EUR' }
  )

  useEffect(() => {
    const seed = async () => {
      const geo = await geoLookup()
      dispatch({ type: 'INITIAL_STATE', payload: geo })
    }

    seed()
  }, [])

  const setCurrency = (currency) => {
    dispatch({ type: 'SET_CURRENCY', payload: currency })
  }

  return (
    <LocaleContext.Provider
      value={{
        setCurrency,
        ...state
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

function ImageWrap(props) {
  if (props.gatsbyImageData) {
    return (
      <GatsbyImage
        className={props.className}
        image={props.gatsbyImageData}
        objectFit={props.objectFit}
        alt={props.alt || ''}
        loading={props.loading}
        style={props.style}
      />
    )
  }

  return (
    <img
      className={props.className}
      src={props.src || props.url}
      alt={props.alt || ''}
      loading={props.loading}
      style={props.style}
    />
  )
}

export default ImageWrap

import React from 'react'

export default function Vimeo (props) {
    return (
      <svg {...props} viewBox="0 0 24 24">
        <g fill="#45BBFF">
          <path
            d="M22.99 6.402c-.099 2.142-1.594 5.073-4.487 8.795-2.992 3.887-5.522 5.83-7.592 5.83-1.283 0-2.368-1.183-3.255-3.55l-1.775-6.511c-.659-2.367-1.364-3.551-2.12-3.551-.165 0-.74.346-1.727 1.036L1 7.118c1.086-.954 2.156-1.908 3.21-2.863 1.448-1.25 2.536-1.909 3.26-1.975 1.712-.165 2.766 1.005 3.161 3.51.428 2.703.724 4.384.89 5.042.494 2.242 1.037 3.362 1.63 3.362.46 0 1.152-.727 2.074-2.183.922-1.454 1.415-2.56 1.481-3.321.132-1.255-.361-1.884-1.48-1.884-.527 0-1.07.121-1.629.36 1.082-3.54 3.147-5.26 6.196-5.162 2.26.067 3.327 1.532 3.196 4.398"
            transform="translate(-407 -3052) translate(119 2352) translate(0 640) translate(1 48) translate(275) translate(12 12)"
          />
        </g>
      </svg>
    )
  }